import { Component, ContentChild, effect, ElementRef, input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { DataInputComponent } from '../data-input.component'
import { CommonModule } from '@angular/common'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { FormControl, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms'
import { get } from 'lodash-es'

@Component({
  selector: 'app-data-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['../../../../../assets/admin.scss'],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class TextFieldDataInputComponent extends DataInputComponent implements OnInit {

  @ContentChild('suffixTemplate') suffixTemplate!: TemplateRef<any>
  @ViewChild('dataInput') dataInput!: ElementRef

  type = input<'text' | 'number' | 'email' | 'password' | 'tel' | 'url'>('text')
  step = input<number>()
  min = input<number>()
  max = input<number>()
  maxLength = input<number>(1000000000)
  required = input<string | boolean>(false)
  patternErrorMessage = input<string>('Invalid input')

  inputValidators: ValidatorFn[] = []
  control: FormControl = new FormControl('', this.inputValidators)

  constructor() {
    super()
    effect(() => {
      this.control.setValue(get(this.data(), this.key(), this.default()))
    })
  }

  ngOnInit(): void {
    this.control.setValue(this.getDefaultValue())
    if (this.pattern()) {
      this.inputValidators.push(Validators.pattern(this.pattern()))
    }
    this.control.setValidators(this.inputValidators)
  }

  handleChange(event: any): void {
    if (this.control.hasError('pattern')) {
      return
    }
    this.updateDataValue(event.target.value)
  }

}
