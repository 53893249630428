import { Component, Inject, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ButtonModule } from '../button/button.module'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule, MatIconButton } from '@angular/material/button'
import { AssetCardComponent } from '../asset-card/asset-card.component'
import { Router, RouterModule } from '@angular/router'
import { EventService } from 'src/app/core/services/event.service'
import { RoutePaths } from 'src/app/core/data/route-paths.data'
import { IAsset } from 'src/app/core/interfaces/asset.interface'
import { AssetMeterPipe } from 'src/app/core/pipes/asset-meter.pipe'
import { DefaultStringPipe } from 'src/app/core/pipes/default-string.pipe'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { IComparisonModalOptions } from 'src/app/core/interfaces/modal.interface'
import { GlobalDataService } from 'src/app/core/services/global-data.service'
import { ComparisonService } from 'src/app/core/services/comparison.service'

@Component({
  selector: 'app-comparison-modal',
  template: `
    <div class="modal">
      <div class="header-bar"></div>
      <div class="header">
        <app-button btn-medium btn-secondary btn-outlined (click)="clearAll()" i18n="@@general_ClearAllButton">Clear all</app-button>
        <span class="title" i18n="@@comparisonModal_Title">Compare Assets</span>
        <button mat-icon-button aria-label="Hide" i18n-aria-label="@@general_HideButton" (click)="bottomSheetRef.dismiss()">
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
      <div class="content">
        <div class="container">
          @for (asset of comparisonService.assets(); track $index) {
            <app-asset-card
              [asset]="asset"
              [showCarousel]="false"
              [comparisonMode]="true"
              [defaultImage]="globalDataService.defaultImage()"
              [currencyCode]="globalDataService.selectedCurrency().code"
              (cardClick)="cardClicked($event)"
              (contactClick)="contactClicked($event)"
              data-testid="asset-card"
            ></app-asset-card>
            <div class="compare-item">
              <span class="compare-label" i18n="@@detailsInformation_LocationLabel">Location</span>
              <span class="compare-value">{{asset.branch_city}}, {{asset.branch_state}}</span>
            </div>
            <div class="compare-item">
              <span class="compare-label" i18n="@@detailsInformation_MeterLabel">Meter</span>
              <span class="compare-value">{{asset | assetMeter: 'short' }}</span>
            </div>
            <div class="compare-item">
              <span class="compare-label" i18n="@@detailsInformation_SerialNumberLabel">Serial #</span>
              <span class="compare-value">{{asset.serial_number | defaultString: '--' }}</span>
            </div>
            <div class="compare-item">
              <span class="compare-label" i18n="@@detailsInformation_EquipmentNumberLabel">Equipment #</span>
              <span class="compare-value">{{asset.display_equipment_number | defaultString: '--' }}</span>
            </div>
            <div class="compare-item">
              <span class="compare-label" i18n="@@detailsInformation_CommentsFromSellerLabel">Comments from Seller</span>
              <span class="compare-value">{{ asset.detailed_description | defaultString: '--' }}</span>
            </div>
            <div class="remove">
              <button mat-icon-button aria-label="Remove" i18n-aria-label="@@general_RemoveButton" (click)="clearAsset(asset)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  `,
  styles: [`
    @use "sass:map";
    @import "src/assets/_colors";
    @import 'src/assets/typography';

    :host {
      width: 100%;
      background-color: map-get($light, ref, white);
      display: block;
    }


    button[mat-icon-button] {
      border: 1px solid map-get($light, other, outlined-border);
    }

    .modal {
      height: 100%;
      display: flex;
      flex-direction: column;

      .header-bar {
        background-color: var(--theme-comparison-modal-header-bg-color);
        color: var(--theme-comparison-modal-header-font-color);
        height: 22px;
      }

      .header {
        border-bottom: 1px solid map-get($light, other, outlined-border);

        height: 60px;
        padding: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        .title {
          @include typography(h6);

          @include mq(large) {
            @include typography(h4);
          }
        }
      }

      .content {
        overflow-x: auto;
        .container {
          padding: 32px;
          max-width: 1080px;
          display: grid;
          grid-auto-flow: column;

          gap: 32px;
          grid-template-rows: repeat(7, auto);
          grid-template-columns: repeat(3, 336px);

          .compare-item {
            padding: 16px 12px 16px 0;
            border-bottom: 1px solid map-get($light, other, outlined-border);
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .compare-label {
              @include typography(body-2);
              color: map-get($light, text, secondary);
            }

            .compare-value {
              @include typography(subtitle-2-medium);
              color: map-get($light, text, primary);
            }
          }

          .remove {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  `],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatIconButton,
    CommonModule,
    ButtonModule,
    AssetCardComponent,
    RouterModule,
    DefaultStringPipe,
    AssetMeterPipe,
  ],
})
export class ComparisonModalComponent {
  protected globalDataService = inject(GlobalDataService)
  protected comparisonService = inject(ComparisonService)
  protected bottomSheetRef = inject<MatBottomSheetRef<ComparisonModalComponent>>(MatBottomSheetRef)
  private _eventService = inject(EventService)
  private _router = inject(Router)

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: IComparisonModalOptions) {}

  cardClicked(assetId: string) {
    this.bottomSheetRef.dismiss()
    this._router.navigate([RoutePaths.Details, assetId])
  }

  contactClicked(asset: IAsset) {
    this._eventService.notify('contactClicked', asset)
  }

  clearAll() {
    this.bottomSheetRef.dismiss()
    this._eventService.notifyClearAllComparisonAssets()
  }

  clearAsset(asset: IAsset) {
    const MINIMUM_ASSETS = 2
    if (this.comparisonService.assets().length === MINIMUM_ASSETS) {
      this.clearAll()
    } else {
      this._eventService.notifyClearComparisonAsset(asset.equipment_number)
    }
  }
}
